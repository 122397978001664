import store from '@/store';
import router from '../../router';
export const colorList = [
  '#0BE076',
  '#FFC619',
  '#4D94FF',
  '#FF9F40',
  '#7373FF',
  '#C773FF',
  '#00CAFC',
  '#FF596A',
  '#FF80CC',
  '#21D9C6',
];
export const matterType = [
  ['租赁合同', '非租赁合同'],
  ['回款编号', '发票号码', '合同编号'],
  ['任务完成申请', '任务汇报', '延期申请'],
];

let tabsList = [];
function screenDate(list, path) {
  list.forEach((item) => {
    if (path == item.menuPath) {
      tabsList.push(item);
    }
    if (item.children && item.children.length) {
      screenDate(item.children, path);
    }
  });
}
//跳转iyou工作台某个页面
export async function jumpAppointRouter(moduleName, path) {
  let menuList = store.state.permission.menuList;
  const menu = menuList.filter((element) => {
    return element.moduleName === moduleName;
  });
  store.commit('permission/UPDATE_SUB_MENU', menu[0].menuList);
  await screenDate(menu[0].menuList, path);
  store.commit('tabs/UPDATE_TABS_LIST', tabsList[0]);
  tabsList = [];
  router.push({
    path: path,
  });
}
